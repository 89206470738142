<template>
  <router-link v-if="showLogo" :to="{ name: 'Home' }">
    <ImgSVG class="img" :src="logoUrl" alt="ucall_logo_branco_svg" />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import ImgSVG from '@/components/ImgSVG'
export default {
  name: 'MenuLogotipo',
  components: {
    ImgSVG
  },
  computed: {
    ...mapGetters('about', ['currentNameRoute'])
  },
  watch: {
    $route: {
      handler: function () {
        this.nameRoute = this.currentNameRoute
        this.logoUrl = require('@/assets/logo.svg')
        const body = document.body
        if (['Cases', 'People'].includes(this.$route.name)) {
          body.classList.remove('custom-brand')
          body.style.setProperty('--brand-color', '')
        } else if (['sobre-nós'].includes(this.$route.params.about) && this.currentNameRoute === 'About') {
          body.classList.remove('custom-brand')
          body.style.setProperty('--brand-color', '')
        } else {
          body.classList.add('custom-brand')
          body.style.setProperty('--brand-color', '#fff')
        }
        /* this.logoUrl = ['People', 'Cases', 'Home', 'Solutions'].includes(this.$route.name) && this.onMenu
          ? require('@/assets/ucall_logo.svg')
          : require('@/assets/logo_branco.svg') */
      },
      immediate: true
    }
  },
  props: {
    onMenu: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      logoUrl: '',
      nameRoute: '',
      showLogo: true // Controla a visibilidade do menu-logotipo
    }
  },
  created () {
    this.$root.$on('hideLogo', () => {
      this.showLogo = false
    })
    this.$root.$on('showLogo', () => {
      this.showLogo = true
    })
  },
  beforeDestroy () {
    this.$root.$off('hideLogo')
    this.$root.$off('showLogo')
  }
}
</script>
<style lang="scss" scoped>
img, .img {
  display: block;
  width: 221px;
  height: 46px;
}

@media (min-width: 768px) {
  img.logo, .img.logo {
    display: none;
  }
}

@media (max-width: 411px) {
  img, .img{
    width: 150px !important;
  }
}

@media screen and (min-width:1600px) {
  .about-logo{
    width: 6rem ;
  }
}
</style>
